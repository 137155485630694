<template>
  <div class="appendix">
    <div v-html="content1" class="content" />
    <van-image
      fit="contain"
      src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/contract7_02.png"
    />
    <div v-html="content2" class="content" />
    <van-image
      fit="contain"
      src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/contract7_pic1.png"
    />
    <div v-html="content3" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, Image,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref, computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const content1 = `序言
香港市场有许多不同种类的投资产品，其中许多都含有衍生元素。因此,熟悉我们所购买的产品和我们要面对的风险比“投资能够为我们带来多少收益”更为重要。在这篇文章中，我们将讨论香港市场上一些常见的衍生产品和有关这些衍生工具基本常识。我们希望这篇文章能够使读者受益，了解更多有关衍生产品的基本知识，如他们的性质，涉及的重大风险等，为更深入的学习衍生产品打下基础。罗列出所有能够影响每只衍生工具表现和风险的因素是不太可能的，您在投资之前必须咨询您的专业投资顾问以便获得更深入的信息。


什么是衍生产品？
衍生产品是一种金融产品，其价值取决于或衍生于另外一个标的资产的价值。期货，远期，期权，认股权证，掉期，掉期期权，结构性票据都是衍生产品。衍生产品可用于套期保值，防范金融风险，或者可以用来根据标的资产、利率或各种金融指数的变动而进行投机。

盈利模式
根据盈利模式，我们可以将衍生产品分为两类，远期和期权。

远期的盈利模式是线性和对称的。这意味着，衍生产品的价值变化同其标的资产的价值变化是同向的，并且变化比例相同。例如，如果恒生指数上涨1％，以恒生指数为标的的近期期货合约理论上也应上涨1％。但现实中，由于受指数成份股构成、未来股息期限结构变化和远期利率的预期影响，上涨幅度可能会与1％有微许偏差。

期权的盈利模式是非线性和非对称的。这意味着，衍生产品的价值变化与标的资产的价值变化可能是不同向的，且变化的比例不尽相同。如上述所讲的例子，恒生指数如果上涨1％，并不意味着投资者所持有的衍生工具也上涨1%。这要取决于期权的类型（看涨期权或看跌期权）、期权的状态（价内期权、等价期权或价外期权）、至到期日的时间等。

期货与远期
期货类似于远期合约，他们以同样的方式定价。两者的主要区别为，期货合约是在交易所买卖的合约，恒生指数期货及H股指数期货就是在交易所买卖的期货合约的例子。期货合约在合同的大小，条款和到期日等方面都有统一的标准。而那些不在交易所交易的合约被称为远期合约，远期合约都在场外交易。另一个区别是期货合约有支付最低保证金的要求，支付保证金的目的是确保投资者有足够的金钱去弥补任何潜在的交易损失。而就远期合约而言，实际支付只在合约到期日发生。从这个意义上说，双方互相承担对手方的风险。

期货术语
合约大小：每份期货合约的合约价值是由交易所本身事先确定的。例如如果有投资者想投资黄金期货，他会发现，由香港交易所所提供的合约，每张价值为100金衡盎司，而由香港商品交易所所提供的合约，每张价值为32金衡盎司。
持有成本：期货价格和现货价格之间的关系可以用持有成本来概括。持有成本是存储成本加上持有金融资产需缴付的利息减去金融资产所产生的收入所得而得来的。
到期日：在期货合约中指定的日期。这是该合约进行交易的最后一天这一天过后这个合约将被终止进行交易。
即月：在一系列合约月份中的第一个到期结算的月份。
期货价格：期货合约在期货市场的交易价格。
初始保证金：当投资者进场建立期货合约部位时，不论是长仓还是空仓缴纳给经纪人的保证金。
长仓：多头部位，未结算的买进期货部位。
维持保证金：投资者为了维持现有部位而必须保持其账户内的最低保证金金额。
逐日盯市制度：在每个交易日结束后，对投资者账户中尚未结算的衍生产品组合价值，以当日结算价为标的进行重新的价值评估。
合约乘数：用以计算每个期货合约的现金价值。例如，恒指期货合约的合约乘数为每指数点50港元，如果一个恒指期货合约现有的报价为16,000点，则此合约的一张合约价值为80万港元（16,000点x50港元）。
空盘量：在期货市场上尚未结清的全部长仓和空仓的数量。
淡仓：尚未结清的空头部位。
现货价格：一项资产在现货市场交易的价格。
价差：可以被定义为期货价格减去现货价格。期货合同的每个交割月份都会有不同的价差。正常情况下，价差是正数，这反映了期货价格通常高于现货价格。
最小价格波动值：买卖价之间的最小价差。

期权
期权实质上是一种权利而不是一种义务。一个期权合约的买方有权利在特定日期或在此日期之前以特定价格买入或卖出一定数量的标的资产，而期权合约的卖方则有义务在期权合约买方行使期权后将相关标的资产买入或卖出。期权的买方以支付期权费的方式换取权利。在期权产品中，我们应当知道“认购期权”和“认沽期权”之间的区别、“美式期权”和“欧式期权”及许多其它术语的含义。你可能对这些名词感到困惑，但不要被它们的名字吓到。当这些术语与期权交易相关联时，每个术语都有其特定的含义。如果你有兴趣了解更多有关期权方面的知识，你需要熟悉这些术语。

期权术语

美式期权：可在到期日或之前任何一交易日行权的期权合约。
等价：等价期权指期权如果被行权时所产生的现金流为零。以一个指数期权为例,当当前指数点位和指数期权的履约价相同时（即现货价格=行使价）,这个指数期权处于实值状态，即等价状态。
看涨期权：期权合约赋予持有人权利，但没有义务，在特定时期内以协约价格购买指定数量的标的资产来做为支付期权费的补偿。看涨期权的买方希望在某一特定日期前股票价格会上升，而看涨期权的卖方则希望在某一特定日期前股票价格会下降，保持稳定，或上升的幅度小于他们在期权费上的盈利。
看涨期权费：看涨期权的成本金额。
备兑认购期权：卖出看涨期权的同时买入等值标的资产的长仓。
备兑认沽期权：卖出看跌期权的同时卖出等值标的资产的淡仓。
信贷价差：卖出期权所得到的溢价超过买入期权时所支付的溢价的差异。
借记价差：买入期权时所支付的溢价超过卖出期权所得到的溢价的差异。
深度价内：深度价内的看涨期权的履约价远低于当前标的工具的现货价格。深度价内的看跌期权的履约价远高于当前标的工具的现货价格。两者都有内在价值。
欧式期权：只能在到期日行权的期权合约。
行权：执行期权的权利买入或卖出标的股票。
行权价：指合约可以被行权时的特定价格，即看涨期权的买方能够购买标的资产或看跌期权的买方可以出售标的资产时的期权价格。它也被称为履约价格。
截止日期：可行使购股权的最后一天。
价内：如果履约价低于标的资产的市场价格,看涨期权就处于实值状态，即价内状态。如果履约价高于标的资产的市场价格,看跌期权就处于实值状态，即价内状态。
内在价值：期权行权价格和基本工具的当前值（现货价格）之间的差异。如果该期权没有正面的货币价值，它处于虚值状态，即价外状态。
无保护期权：是指没有对冲标的资产部位的看涨期权或看跌期权。
价外：如果行权或履约价高于标的股票当前的市场价格，看涨期权就处于虚值状态，即价外
状态。如果行权或履约价低于标的股票当前的市场价格，看跌期权就处于虚值状态，即价外状态。如果一个期权在到期时还处于虚值状态，其持有人只能“放弃期权”，期权将变得毫无价值。
看跌期权：期权合约赋予持有人权利，但没有义务，在特定时期内以协约价格卖出指定数量的标的资产。看跌期权的买方希望在某一特定日期前股票价格会上升，而看跌期权的卖方则希望在某一特定日期前股票价格会下降，保持稳定，或下降的幅度小于他们在期权费上的盈利。
跨式期权组合：指一种包含相同行使价和到期日的看涨期权和看跌期权的组合。
异价跨式组合：指包含不同行使价但相同标的资产和到期日的看涨期权和看跌期权的组合。多数异价跨式组合都涉及等价期权。
合成正股长仓：同时沽出看跌期权和买入看涨期权。
合成正股短仓：同时沽出看涨期权和买入看跌期权。
时间衰减：期权在一定的时期内随着时间的流逝而导致的时间溢价价值的变动，这种变动取
决于期权距离到期的时间。
时间价值：期权的价值与其内在价值之差。价外期权的价值没有内在价值只有时间价值。
波动性：在特定的时期，根据期权每天的历史价格，以价格衡量标的资产向上或向下的趋势。从数学的角度来说，波动是每日价格百分比变化的标准差。有两种波动性：历史波动性-期权历史价格波动的统计测量。隐含波动性-衡量期权溢价是否相对昂贵或者便宜。隐含波动性是基于现有期权交易溢价计算的。
卖方：卖出期权的个体。

权证
权证是香港最常见的具有期权性质的衍生工具，它是一种可以在交易所交易的证券产品。香港交易市场中的权证分为两类，分别是股票权证（公司认股证）和衍生权证。股票权证是由公司发行的看涨期权，给予权证持有人买入该公司或该公司子公司的新股份的权利，通常行权价都会较发行时的市场价格高。当权证持有人行使权利时，公司必须向持股人发行新股份（全部已发行的股份数量便会增加）。衍生权证一般由协力厂商机构发行，例如投资银行，它们可以是看涨或看跌权证，目标资产可以是单一的股票、一篮子股票、指数、货币、商品等，大部份衍生权证都是以现金结算的。
衍生工具的应用
衍生工具有很多用途，一般为了实现以下目的：
-提升投资收益
-参与不同资产类别
-杠杆效应
-做多／做空敞口和风险对冲
提升投资收益
如果投资者预期股票市场将会在未来数月不会有大幅度下跌，即使一些特定股票低于特定价格时，投资者仍然愿意以该特定价格，买入该些股票作为投资。

该投资者可以利用股票挂钩票据(ELN)，在交易市场直接卖出看跌期权或卖出股票期权，其中主要的差别是，投资者投资ELN时，可以基于自身的要求，定制执行价格水平、合约大小和合约到期日。

如果合约到期日，该标的股票并没有下跌至低于执行价格水平，投资者可以无须买入该股票，通过获取期权金，提高投资收益。但当市场下跌时，投资者便需要以高于市场的价格买入该股票，如果股票价格大幅下跌的话，投资者便会面临重大亏损的风险。

参与不同资产类别
“参与不同资产类别”即代表投资者可以参与或买入卖出不同类别的资产，例如可以通过衍生工具持有股票和外汇敞口。举个例子，海外投资者是无法参与中国大陆的“A股”市场，但是通过合成ETF，投资者便可以间接参与“A股”市场，该些合成ETF的主要投资目的是利用衍生工具追踪（或复制）市场指数的表现，通过持有合成ETF，投资者尽管未能直接持有“Ａ股”，但是仍可以通过持有合成ETF获取“A股”表现。

杠杆效应
当投资者发现一个明确的市场趋势，如果他想更多参与市场，他可以买入与其投资数量相同的权证，然而，投资者所承担的风险将非常高!

例如，投资者想以杠杆的方式提升某一股票的敞口，某一看涨权证中标的股票的市场价格为每股100港元，一个该股票3个月到期，而执行价格为100港元的看涨权证现时价格是0.5港元。假设该股票的衍生权证的转换比例为10:1，总投资金额为100,000港元。

策略A(股票)：买入1000股股票
策略B(权证)：买入200,000股执行价格为100港元的看涨权证

在这3个月内两个组合可能的投资收益和的回报率：
`.replace(/\r?\n/g, '<br />');
const content2 = `观察结果：从上面的例子，我们可以发现通过权证交易可以实现如下目的：
策略B收益的斜率是远远高于策略A收益的斜率，可以发现策略B对标的股票价格的变动较策略A更为敏感，这个杠杆效应可以帮助投资者在牛市时增加参与度。相反，如果目标股票的价值保持不变，直到权证到期（其初始值100港元）。策略B价值急剧下降到零，回报率会等于-100%。

做多／做空敞口和风险对冲
如果购买股票风险过大，投资者可以尝试使用“看跌权证”对冲下跌风险。结合看跌权证和股票的特征，投资者可以在不利市场形势中获得更好的保护。当投资者因某种原因要继续持有该股票，它可以提供一个很好的避难策略。例如，如果某股票的市场价格是每股25美元，一个该股票6个月到期，而执行价格为23港元的看跌权证的价格现时是0.10港元。投资者决定买入4000股股票，该股票的总投资为100,000港元。此外，买入该股40,000股的看跌权证。

策略A(股票)：买入4,000股股票
策略B(股票＋权证)：买入4,000股股票和买入该股40,000股的看跌权证
在这6个月内两个组合可能的投资收益和的回报率：
`.replace(/\r?\n/g, '<br />');
const content3 = `观察结果：从上面的例子，我们可以发现通过权证交易可以达到如下目的：当股价下跌时，看跌权证可用于对冲多头的股票敞口。看跌权证价格上涨的相应价值将抵消股票的损失。它可以帮助投资者在熊市或市场不明朗时，维持投资组合的价值，并继续持有而不是出售的股票，避免错过了一个股票潜在反弹的机会。

可赎回牛/熊证
除了衍生权证外，可赎回牛/熊证是我们可以在香港买卖的最流行的衍生产品之一。其他衍生工具一样，它的价值取决于和它相关联的资产的表现。它还向投资者提供关于标的资产的杠杆投资，标的资产可以是单一股票或指数。

可赎回牛/熊证有两种类型的合同，即可赎回牛证和可赎回熊证。投资者购买可赎回牛证，表示其看好标的资产的前景并打算捕获其潜在的价格升值所带来的收益。相反，投资者购买可赎回熊证，表示其看淡标的资产的前景，并试图在市场下跌时获利。

可赎回牛/熊证的定价为标的资产的现价与可赎回牛/熊证履约价之间的差别，再加上一个小的溢价（通常是融资成本）。履约价可以等于或低于（牛证）/高于（熊证）赎回价。赎回价也被称为“止损”价。如果标的资产价格在到期前的任何时间达到赎回价，可赎回牛/熊证将提前到期。可赎回牛/熊证的发行人必须赎回牛熊证，可赎回牛/熊证的交易将立即终止，这被称为强制性赎回事件。不过，如果强制性赎回事件没有发生过，可赎回牛熊证将会在预定日期届满后过期。
与衍生工具相关的主要风险
涉及衍生工具的主要风险，包括：
1.对手方风险当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
2.市场风险
衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动将直接影响衍生产品的价格，这是标的资产的投资风险。
3.流动风险
指衍生产品可能很难出售或以低成本转换成现金的风险。一些衍生产品在到期之前，可能难以出售和转换成现金。如果不能卖掉这些衍生产品，投资者在能够拿回他/她的资金之前，不得不等待直到衍生产品到期为止。
4.利率风险
利率及所有衍生产品的价值是密切相关的。任何衍生产品最终会被交换为“资产”和“钱”，或在两种货币之间转换。事实是，“钱”是必然和利率相联系，因此，利率的变化肯定会影响衍生产品的价值。
5.杠杆风险
虽然衍生权证的价格可能只是标的资产价格的一小部分，衍生权证价值的变化速度可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。

免责声明
本档仅供一般资用途，并无考虑任何人士的目标或需要而制作。本档并无就其中所述任何产品或投资是否适合或切合任何个别人士的情况作出任何声明或推荐，亦不会就此承担任何责任。本档所载资并非专业意见，及任何人士应对其加以倚赖。本档并构成，亦无意作为，也不应被诠释为任何意见，要约或游投资于本档内所述的任何产品或投资。本文件并不是，且亦非为满足任何司法权区有关投资者教育或其他方面的任何特定监管要求而设计。本档并未经任何司法权区的任何监管机构检阅。尽管蜂投证券有限公司(下称“蜂投证券”)已尽努确保本文件所载资料的准确性及可靠性，但蜂投证券并保证其为任何目的而设的准确性、完备性、可靠性或合适性。投资者应依赖本档的内容，而应以他们作出及倚赖他们本身所作评估及评价，并自行进他们本身的调查和咨询，以及寻求所有必需的独立意见。在适用法允许的最高情况下，蜂投证券之雇员、代理及代表明确表示概不就有关或因本文件，特别是以下各项事宜而引起的任何性质的损失、损害、费用或开支承担全部或任何责任（不论属侵权行为或合约或其他方面者）：
-本档的内容或任何遗漏，包括任何明示或暗示声明、陈述或结论；
-向任何人士提供或让任何人士使用本档或其任何部分；
-编制本档所载的资料；及
-任何人士倚赖、披露或使用本文件所载或有关的任何资料或陈述。
投资涉及风险。投资者应注意，投资的价值可跌亦可升，过往的表现一定可以预示日后的表现。本档并非，亦无意总览本档所述产品或投资可能牵涉的所有风险。投资者须基于本身的投资目标、财政况及特定需要而作出投资决定；在有需要的情况下，应于作出任何投资前咨询独专业顾问。于作出任何投资决定前，投资者应细阅及了解有关该等产品或投资的所有发售文件，以及其中所载的风险披露声明及风险警告。`.replace(/\r?\n/g, '<br />');
export default {
  name: 'appendix',
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
  },
  data() {
    return {
      content1,
      content2,
      content3,
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const httprequest = this.isAddAccount
        ? httpAddApi.updateDerivativeAssessmentChoices : httpApi.postAsessIndex;
      const res = await httprequest({
        ...data,
        derive_sense: '是',
      });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(false);
    const submitForm = ref({});
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '衍生产品基本常识');
      store.commit('setStepsShow', false);
      const httprequest = isAddAccount.value
        ? httpAddApi.getDerivativeAssessmentChoices : httpApi.getAsessIndex;
      const result = await httprequest();
      const { data } = result;
      isDisabled.value = data.derive_sense === '是';
      submitForm.value = data;
      httpApi.formLog(submitForm, isDisabled);
    });
    return {
      isAddAccount,
      isDisabled,
      submitForm,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
